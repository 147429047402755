export const startingFrames = {
  0: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  1: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  2: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  3: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  4: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  5: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  6: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  7: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  8: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
  9: {
    roll1: null,
    roll2: null,
    roll3: null,
    additionalRoll1: null,
    additionalRoll2: null,
    cumulativeScore: null,
  },
};
